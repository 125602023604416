<template>
    <div class="about">
        <div class="facts">
            <h2>{{ facts }}</h2>
            <div class="facts__items">
                <gallery
                    isHtml
                    pagination
                    autoplay
                    effect="fade"
                >
                    <gallery-slide v-for="(item,i) in slidesHTML" :key="i" data-swiper-autoplay="4000">
                            <div class="facts__gallery__image--text">
                                <div class="facts__gallery__image--text-flex">
                                    <img :src="item.preview" />
                                </div>
                                <div class="facts__gallery__text">
                                    <z-key-indicator
                                        size="l"
                                        noCounter
                                        :value="item.hero"
                                        :prefix="item.hero === '1' ? '№' : ''"
                                    ></z-key-indicator>
                                    <p class="facts__gallery__text-title">{{item.title}}</p>
                                    <p class="facts__gallery__text-description">{{item.description}}</p>
                                </div>
                            </div>
                    </gallery-slide>
                </gallery>
            </div>
        </div>
        <div class="results">

            <gallery :slides="slidesHTML" isHtml></gallery>
            <h2>{{results}}</h2>
            <div class="results__items">
                <div class="results__item" v-for="(indicator, i) in indicators" :key="i">
                    <z-key-indicator
                        :value="indicator.value"
                        :name="indicator.name"
                        :unit="indicator.unit"
                        :prefix="indicator.prefix"
                    >
                        <template v-slot:icon>
                            <z-icon :name="indicator.icon"></z-icon>
                        </template>
                        <template v-slot:unit v-if="indicator.arrow">
                            <span class="results__item--percent">%</span><z-icon name="arrow_up" width="15px" height="22px"></z-icon>
                        </template>
                    </z-key-indicator>
                </div>
            </div>
            <z-button tag="a" href="/en/investors/reports-and-results/" class="results__button" kind="primary" rounded>{{allResults}}</z-button>
        </div>
        <template v-if="terminals.length">
            <h2>{{terminalsHeader}}</h2>
            <div class="row">
                <div
                    v-for="(item, i) in terminals"
                    :key="i"
                    class="col-default-4 col-mobile-12"
                >
                    <card
                        :link="item.link"
                        :overlay="'rgba(54,54,54,0.6)'"
                        :theme="'background'"
                        :image="item.image"
                        :shadow="false"
                        :mark="false"
                        padding="300px"
                    >
                        <template v-slot:title>
                            {{ item.name }}
                        </template>
                        <template v-slot:bottom-right>
                            <img :src="item.icon" height="32px">
                        </template>
                    </card>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'services',
    data () {
        return {
            more: localize({
                'ru': 'Подробнее',
                'en': 'Learn more'
            }),
            facts: localize({
                'ru': 'Наши преимущества',
                'en': 'Key strengths',
                'cn': '关键数据和事实'
            }),
            results: localize({
                'ru': 'Ключевые результаты Q4 2024',
                'en': 'Q4 2024 Highlights',
                'cn': '2022年上半年工作结果'
            }),
            allResults: localize({
                'ru': 'Все результаты',
                'en': 'All results',
                'cn': '所有结果'
            }),
            indicators: localize({
                ru: [
                    {
                        icon: 'about-1',
                        value: '299',
                        unit: 'тыс. TEU',
                        name: 'консолидированный морской контейнерооборот'
                    },
                    {
                        icon: 'about-3',
                        value: '1,6',
                        unit: 'млн тонн',
                        name: 'консолидированный морской грузооборот неконтейнерных грузов'
                    },
                    {
                        icon: 'about-2',
                        value: '34,7',
                        unit: '%',
                        name: 'рост контейнерооборота в&nbsp;Балтийском бассейне'
                    },
                    {
                        icon: 'about-4',
                        value: '25,5',
                        unit: '%',
                        name: 'рост перевалки прочих неконтейнерных грузов'
                    }
                ],
                en: [
                    {
                        icon: 'about-1',
                        value: '299 ',
                        unit: 'thousand&nbsp;TEU',
                        name: 'consolidated marine container throughput'
                    },
                    {
                        icon: 'about-3',
                        value: '1.6',
                        unit: 'million&nbsp;tonnes',
                        name: 'consolidated marine non-containerised cargo throughput'
                    },
                    {
                        icon: 'about-2',
                        value: '34.7',
                        unit: '%',
                        name: 'increase in&nbsp;container throughput in&nbsp;the Baltic basin'
                    },
                    {
                        icon: 'about-4',
                        value: '25.5',
                        unit: '%',
                        name: 'growth in&nbsp;other non-containerised cargo throughput'
                    }
                ],
                cn: [
                    {
                        icon: 'about-1',
                        value: '61',
                        unit: '万1千 TEU(标准集装箱) (-22,6%)',
                        name: '海运码头综合集装箱货运周转量'
                    },
                    {
                        icon: 'about-2',
                        value: '1.456',
                        unit: '亿美元(+28.0%)',
                        name: '经调整的EBITDA'
                    },
                    {
                        icon: 'about-3',
                        value: '1.513',
                        unit: '亿美元(+145.9%)',
                        name: '自由现金流'
                    },
                    {
                        icon: 'about-4',
                        value: '53.6',
                        unit: '%',
                        name: '可比基础上始终保持较高的 EBITDA 利润率'
                    }
                ]
            }),
            terminalsHeader: localize({
                'ru': 'Наши терминалы',
                'en': 'Our terminals',
                'cn': '码头是'
            }),
            slidesHTML: localize({
                ru: [
                    {
                        'name': 'Название фотки',
                        'preview': '/images/components/company-about/1.jpg',
                        'hero': '1',
                        'title': 'Оператор контейнерных терминалов в России',
                        'description': 'Компания является лидером по грузообороту и пропускной способности на российском рынке',
                        'type': 'photo'
                    },
                    {
                        'name': 'Название фотки 2',
                        'preview': '/images/components/company-about/2.jpg',
                        'type': 'photo',
                        'hero': '7',
                        'title': 'Морских контейнерных и многофункциональных терминалов',
                        'description': 'Компания управляет терминалами в России и Финляндии, имея присутствие в двух ключевых морских бассейнах'
                    },
                    {
                        'name': 'Название фотки 2',
                        'preview': '/images/components/company-about/3.jpg',
                        'type': 'photo',
                        'hero': 'около 400 га',
                        'title': 'Общая площадь морских терминалов',
                        'description': 'Терминалы компании обслуживают 28 причалов с общей длиной причальной стенки 5,6 км'
                    },
                    {
                        'name': 'Название фотки 2',
                        'preview': '/images/components/company-about/4.jpg',
                        'type': 'photo',
                        'hero': '3200',
                        'title': 'Сотрудников в штате',
                        'description': 'Профессионалы обеспечивают высокие качество и скорость обработки любых грузов'
                    }
                ],
                en: [
                    {
                        'name': 'Название фотки',
                        'preview': '/images/components/company-about/1.jpg',
                        'hero': '1',
                        'title': 'Container terminal operator in Russia',
                        'description': 'The company stands as a leader in terms of cargo throughput and capacity in the Russian market',
                        'type': 'photo'
                    },
                    {
                        'name': 'Название фотки',
                        'preview': '/images/components/company-about/2.jpg',
                        'hero': '7',
                        'title': 'Marine container and multipurpose terminals',
                        'description': 'The Company operates in Russia and Finland, covering 2 major sea basins',
                        'type': 'photo'
                    },
                    {
                        'name': 'Название фотки',
                        'preview': '/images/components/company-about/3.jpg',
                        'hero': 'about 400 ha',
                        'title': 'Total area of marine terminals',
                        'description': "The company's terminals serve 28 berths with a total quay wall of 5.6 km",
                        'type': 'photo'
                    },
                    {
                        'name': 'Название фотки',
                        'preview': '/images/components/company-about/4.jpg',
                        'hero': '3200',
                        'title': 'Employees',
                        'description': 'Professionals ensure high quality and speed of any cargo handling',
                        'type': 'photo'
                    }
                ],
                cn: [
                    {
                        'name': 'Название фотки',
                        'preview': '/images/components/company-about/1.jpg',
                        'hero': '<span class="z-key-indicator__prefix">第</span>1<span class="z-key-indicator__prefix">名</span>',
                        'title': '俄罗斯集装箱码头运营商',
                        'description': '在货物周转量和码头的吞吐能力方面，占俄罗斯集装箱市场的领先地位。几乎每三个进出口俄罗斯的集装箱中就有一个是在本集团的码头处理。',
                        'type': 'photo'},
                    {
                        'name': 'Название фотки 2',
                        'preview': '/images/components/company-about/2.jpg',
                        'type': 'photo',
                        'hero': '7<span class="z-key-indicator__prefix">个</span>',
                        'title': '多功能海运码头',
                        'description': '位于俄罗斯和芬兰 (包括合资企业).'
                    },
                    {
                        'name': 'Название фотки 2',
                        'preview': '/images/components/company-about/3.jpg',
                        'type': 'photo',
                        'hero': '323<span class="z-key-indicator__prefix">公顷</span>',
                        'title': '占地面 (相当于450多个足球场)',
                        'description': '在主要海水区的码头长度5 公里'
                    },
                    {
                        'name': 'Название фотки 2',
                        'preview': '/images/components/company-about/4.jpg',
                        'type': 'photo',
                        'hero': '3200<span class="z-key-indicator__prefix">名</span>',
                        'title': '员工'
                    }
                ]
            }),
            terminals: this.$root.app.components['terminals-list']
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
