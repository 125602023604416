<template>
    <div class="news-detail">
        <div class="container">
            <div class="row">
                <div class="page__aside col-default-3 col-tablet-hide">
                    <z-button tag="a" :href="article['back-link']" kind="text" class="u-bottom-margin--l-desktop">
                        <template v-slot:left>
                            <z-icon name="arrow" dir="left" width="14" height="14"></z-icon>
                        </template>
                        {{ titleBackLink }}
                    </z-button>

                    <navigation-left></navigation-left>

                    <card
                        v-for="(document, i) in article.documents"
                        :key="i"
                        style="min-height: 250px"
                        class="u-bottom-margin--l-desktop"
                    >
                        <template v-slot:title>{{ document.name }}</template>
                        <template v-slot:content>
                            <span class="card__file-info">{{ document.size }}, {{ document.extension }}</span>
                        </template>
                        <template v-slot:bottom-left>
                            <z-link :href="document.link" download target="_blank">
                                <z-icon name="download" width="48" height="48"></z-icon>
                            </z-link>
                        </template>
                        <template v-slot:bottom-right>
                            <card-icon
                                theme="grey"
                                size="m"
                            >
                                <z-icon name="databank" width="64" height="64"></z-icon>
                            </card-icon>
                        </template>
                    </card>
                </div>
                <main class="content page__main col-default-9 col-tablet-12">
                    <article>
                        <div class="news-detail__info">
                            <span class="news-detail__date">{{ article.date | momentFilter('DD MMMM YYYY') }}</span>
                            <span class="news-detail__tags" v-if="article.categories.length">
                                <span
                                    v-for="(tag, i) in article.categories"
                                    :key="i"
                                >
                                    {{ tag }}
                                </span>
                            </span>
                        </div>
                        <h2 v-html="article.name"></h2>
                        <div v-if="article.detail" v-html="article.detail" class="html-content"></div>

                        <gallery
                            class="u-top-margin--xl-desktop"
                            v-if="article.gallery.length"
                            :modal="true"
                            :pagination="false"
                            :slides="article.gallery"
                        ></gallery>

                        <template v-if="article.news.length">
                            <h2 v-html="titleNewsMore"></h2>
                            <div class="row" equal-height=".card">
                                <div
                                    v-for="(item, i) in article.news"
                                    :key="i"
                                    class="news-detail__item"
                                >
                                    <card
                                        :link="item.link"
                                        :overlay="item.image ? 'rgba(0,0,0,0.35)' : ''"
                                        :theme="item.image ? 'background' : 'white'"
                                        :image="item.image"
                                        style="min-height: 325px"
                                        equal-height-max-breakpoint="mobile"
                                    >
                                        <template v-slot:title>
                                            {{ item.name }}
                                        </template>
                                        <template v-slot:content v-if="item.detail">
                                            {{ item.detail }}
                                        </template>
                                        <template v-slot:bottom-left>
                                            <span class="card__tag" v-if="item.categories">{{ item.categories[0] }}</span>
                                            <span class="card__date" v-if="item.date">{{ item.date | momentFilter('DD MMMM YYYY') }}</span>
                                        </template>
                                    </card>
                                </div>
                            </div>
                        </template>
                    </article>
                    <!-- <div class="page-tools">
                        <div class="page-tools__inner">
                            <div class="page-tools__item">
                                <z-share
                                    :services="['vkontakte','twitter','linkedin']"
                                    :bare="true"
                                    :lang="lang"
                                    vertical
                                    template="aside"
                                    :title="article.name"
                                    :image="getFullUrl(article.image)"
                                ></z-share>
                            </div>
                            <div class="page-tools__item">
                                <z-icon @click="print" name="print" width="48" height="48"></z-icon>
                            </div>
                        </div>
                    </div> -->
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { localize } from '@/utils/i18n'

export default {
    name: 'news-detail',
    props: {
        component: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            lang: this.$root.lang,
            titleBackLink: localize({
                'ru': 'К списку новостей',
                'en': 'Back to the news list',
                'cn': '返回新闻列表'
            }),
            titleNews: localize({
                'ru': 'Новости',
                'en': 'News'
            }),
            titleNewsMore: localize({
                'ru': 'Читайте также',
                'en': 'Read also',
                'cn': '另请阅读'
            }),
            article: this.$root.app.components[this.component]
        }
    },
    methods: {
        ...mapActions(['SetHeroTitle', 'SetHeroSrc']),
        print () {
            window.print()
        },
        getFullUrl (link) {
            return location.origin + link
        }
    },
    created () {
        // this.SetHeroTitle(this.article.name)
        this.SetHeroTitle(this.titleNews)
        this.SetHeroSrc(this.article.image)
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
