<template>
    <div class="register-page">
        <z-lead class="u-bottom-margin--l-desktop">
            На основании пункта 1 статьи 189 Гражданского кодекса Российской Федерации извещаем о прекращении доверенностей.
        </z-lead>
        <z-table
            :columns="['Регистрационный номер', 'Дата выдачи', 'Дата прекращения']"
        >
            <z-table-row v-for="item in trustItemsList">
                <z-table-col index="0">
                    {{ item.regNumber }}
                </z-table-col>
                <z-table-col index="1">
                    {{ item.issueDate }}
                </z-table-col>
                <z-table-col index="2">
                    {{ item.endDate }}
                </z-table-col>
            </z-table-row>
        </z-table>
        <z-pagination
            :data="navData"
            showPrev
            showNext
            showNav
            theme="corps"
            class="procedures__pagination"
            @change-page="pageChange"
        />
    </div>
</template>
<script>
export default {
    name: 'RegistrationPage',
    data () {
        return {
            trustItems: this.$root.app.components['trust-items'],
            currentItems: [],
            nav: {
                currentPage: 1
            },
            trustItemsCount: 10
        }
    },
    computed: {
        navData () {
            return {
                currentPage: this.nav.currentPage,
                totalPages: Math.ceil(this.trustItems.length / this.trustItemsCount),
                pageRange: 1
            }
        },
        trustItemsList () {
            const firstIndex = (this.nav.currentPage - 1) * this.trustItemsCount

            return this.trustItems.slice(firstIndex, firstIndex + this.trustItemsCount)
        }
    },
    methods: {
        pageChange (data) {
            this.nav.currentPage = data.page
        }
    }
}
</script>
<style lang="scss">
.register-page {
    padding-top: 2rem;
    padding-bottom: 7.5rem;

    & .z-pagination {
        margin: 32px auto 0;
    }
}
</style>
